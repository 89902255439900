import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaExchangeAlt, FaWallet, FaDollarSign, FaArrowRight, FaInfoCircle } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';

const WarningMessage = ({ amount, limit }) => {
  if (!amount || !limit || parseFloat(amount) <= limit) return null;

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      className="mt-2 text-red-500 text-sm flex items-center gap-2"
    >
      <FaInfoCircle />
      <span>
        Amount exceeds your remaining limit of {limit.toLocaleString()} BIDZ
      </span>
    </motion.div>
  );
};

const ExchangeForm = () => {
  const { address } = useAccount();
  const [bidzAmount, setBidzAmount] = useState('');
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dailyLimitReached, setDailyLimitReached] = useState(false);
  const [currentRate, setCurrentRate] = useState(null);
  const [estimatedUsd, setEstimatedUsd] = useState(0);
  
  // Limits states
  const [dailyLimit, setDailyLimit] = useState(null);
  const [dailyExchanged, setDailyExchanged] = useState(0);
  const [remainingLimit, setRemainingLimit] = useState(null);
  const [monthlyLimit, setMonthlyLimit] = useState(null);
  const [monthlyExchanged, setMonthlyExchanged] = useState(0);
  const [monthlyRemaining, setMonthlyRemaining] = useState(null);

  const isMobile = window.innerWidth <= 768;

  const companyAddress = '0xfc4B0378e8eAD18DBFce579a01599a5654bCCBe8';
  const bidzTokenAddress = '0x20de6118c3672659e488d1d45279cdf77391fbdc';

  const bidzTokenAbi = [
    {
      constant: false,
      inputs: [
        { name: 'recipient', type: 'address' },
        { name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function'
    }
  ];

  useEffect(() => {
    if (address) {
      fetchUserIdAndLimits();
      fetchRate();
    }
  }, [address]);

  const fetchRate = async () => {
    try {
      const response = await fetch('https://bidzswap.bidz.store/api/exchange/rate');
      const data = await response.json();
      setCurrentRate(data.price);
      if (bidzAmount) {
        setEstimatedUsd(bidzAmount * data.price);
      }
    } catch (error) {
      console.error('Error fetching rate:', error);
      toast.error('Failed to fetch current rate');
    }
  };

  const fetchUserIdAndLimits = async () => {
    try {
      const userResponse = await fetch(`https://bidzswap.bidz.store/api/exchange/user?bidzWallet=${address}`);
      const userData = await userResponse.json();

      if (userData && userData.userId) {
        setUserId(userData.userId);
        const limitsResponse = await fetch(`https://bidzswap.bidz.store/api/exchange/exchange-limits?userId=${userData.userId}`);
        const limitsData = await limitsResponse.json();
        
        // Daily limits
        const dailyLimitAmount = limitsData.daily_limit || 1000;
        const dailyExchangedAmount = limitsData.daily_exchanged || 0;
        const dailyRemaining = Math.max(0, dailyLimitAmount - dailyExchangedAmount);

        // Monthly limits
        const monthlyLimitAmount = limitsData.monthly_limit || 30000;
        const monthlyExchangedAmount = limitsData.monthly_exchanged || 0;
        const monthlyRemaining = Math.max(0, monthlyLimitAmount - monthlyExchangedAmount);

        // Set all states
        setDailyLimit(dailyLimitAmount);
        setDailyExchanged(dailyExchangedAmount);
        setRemainingLimit(dailyRemaining);
        setDailyLimitReached(dailyRemaining <= 0);

        setMonthlyLimit(monthlyLimitAmount);
        setMonthlyExchanged(monthlyExchangedAmount);
        setMonthlyRemaining(monthlyRemaining);
      } else {
        toast.error('User not found for this wallet address.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to fetch user information.');
    }
  };

  const handleAmountChange = (e) => {
    let value = e.target.value;
    
    // Remove any non-numeric characters except decimal point
    value = value.replace(/[^\d.]|\.(?=.*\.)/g, '');
    
    // Don't allow empty or invalid values
    if (value === '' || isNaN(value)) {
      setBidzAmount('');
      setEstimatedUsd(0);
      return;
    }

    const numValue = parseFloat(value);

    // Set the value always
    setBidzAmount(value);
    
    // Show warnings without modifying the input value
    if (numValue < 100) {
      toast.info('Minimum amount is 100 BIDZ');
    } else if (numValue > remainingLimit) {
      toast.warning(`Daily limit: You can only exchange up to ${remainingLimit.toLocaleString()} BIDZ today`);
    } else if (numValue > monthlyRemaining) {
      toast.warning(`Monthly limit: You can only exchange up to ${monthlyRemaining.toLocaleString()} BIDZ this month`);
    }

    // Update estimated USD
    if (currentRate) {
      setEstimatedUsd(numValue * currentRate);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }

    const amount = parseFloat(bidzAmount);

    if (amount < 100) {
      toast.error("Minimum exchange amount is 100 BIDZ");
      return;
    }

    if (amount > remainingLimit) {
      toast.error(`Daily limit: You can only exchange up to ${remainingLimit.toLocaleString()} BIDZ today`);
      return;
    }

    if (amount > monthlyRemaining) {
      toast.error(`Monthly limit: You can only exchange up to ${monthlyRemaining.toLocaleString()} BIDZ this month`);
      return;
    }

    try {
      setLoading(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const bidzContract = new ethers.Contract(bidzTokenAddress, bidzTokenAbi, signer);
      const bidzAmountInWei = ethers.utils.parseEther(bidzAmount.toString());

      const transaction = await bidzContract.transfer(companyAddress, bidzAmountInWei);
      const receipt = await transaction.wait();

      const response = await fetch('https://bidzswap.bidz.store/api/exchange/convert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId,
          bidz_wallet: address,
          bidzAmount: amount,
          transactionHash: receipt.transactionHash,
          estimatedUsd: estimatedUsd
        }),
      });

      const responseData = await response.json();
      
      if (response.ok) {
        toast.success(`Successfully exchanged ${bidzAmount} BIDZ for $${estimatedUsd.toFixed(2)} USD`);
        setBidzAmount('');
        setEstimatedUsd(0);
        fetchUserIdAndLimits();
      } else {
        toast.error(responseData.error || 'Exchange failed');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message || 'Transaction failed');
    } finally {
      setLoading(false);
    }
  };

  const LimitInfo = () => (
    <div className={`mt-4 space-y-4 ${isMobile ? 'text-xs' : 'text-sm'} text-gray-600 dark:text-gray-400`}>
      <div className="p-3 bg-violet-50 dark:bg-violet-900/20 rounded-lg">
        <h3 className="font-semibold mb-2 text-violet-600 dark:text-violet-400 flex items-center justify-between">
          <span>Daily Limits</span>
          <span className={`${remainingLimit < 200 ? 'text-red-500' : 'text-green-500'}`}>
            {remainingLimit?.toLocaleString()} BIDZ remaining
          </span>
        </h3>
        <div className="space-y-1">
          <p>
            <FaInfoCircle className="inline mr-1" />
            Limit: {dailyLimit?.toLocaleString()} BIDZ
          </p>
          <p>
            <FaInfoCircle className="inline mr-1" />
            Swaped: {dailyExchanged?.toLocaleString()} BIDZ
          </p>
          <div className="w-full bg-violet-200 dark:bg-violet-800 rounded-full h-2 mt-2">
            <div 
              className={`rounded-full h-2 transition-all duration-300 ${
                remainingLimit < 200 ? 'bg-red-500' : 'bg-violet-600 dark:bg-violet-400'
              }`}
              style={{ width: `${(dailyExchanged / dailyLimit) * 100}%` }}
            />
          </div>
        </div>
      </div>

      <div className="p-3 bg-indigo-50 dark:bg-indigo-900/20 rounded-lg">
        <h3 className="font-semibold mb-2 text-indigo-600 dark:text-indigo-400 flex items-center justify-between">
          <span>Monthly Limits</span>
          <span className={`${monthlyRemaining < 1000 ? 'text-red-500' : 'text-green-500'}`}>
            {monthlyRemaining?.toLocaleString()} BIDZ remaining
          </span>
        </h3>
        <div className="space-y-1">
          <p>
            <FaInfoCircle className="inline mr-1" />
            Limit: {monthlyLimit?.toLocaleString()} BIDZ
          </p>
          <p>
            <FaInfoCircle className="inline mr-1" />
            Swaped: {monthlyExchanged?.toLocaleString()} BIDZ
          </p>
          <div className="w-full bg-indigo-200 dark:bg-indigo-800 rounded-full h-2 mt-2">
            <div 
              className={`rounded-full h-2 transition-all duration-300 ${
                monthlyRemaining < 1000 ? 'bg-red-500' : 'bg-indigo-600 dark:bg-indigo-400'
              }`}
              style={{ width: `${(monthlyExchanged / monthlyLimit) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  if (!address) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-xl mx-auto text-center p-8 bg-white/90 dark:bg-slate-800/90 rounded-2xl shadow-xl">
          <FaWallet className="text-4xl mx-auto mb-4 text-violet-500" />
          <h2 className="text-2xl font-bold mb-4">Connect Your Wallet</h2>
          <p className="text-gray-600 dark:text-gray-400">
            Please connect your wallet to use the swap.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`container mx-auto ${isMobile ? 'px-3 py-4' : 'px-4 py-8'}`}>
      <ToastContainer
        position={isMobile ? "bottom-center" : "top-right"}
        autoClose={3000}
        hideProgressBar={isMobile}
        closeButton={!isMobile}
        draggable={!isMobile}
      />
      
      <div className="max-w-xl mx-auto">
        <div className="backdrop-blur-lg bg-white/90 dark:bg-slate-800/90 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700">
          <div className={`${isMobile ? 'p-4' : 'p-8'} border-b border-gray-200 dark:border-gray-700 bg-gradient-to-r from-violet-500/10 to-indigo-500/10`}>
            <motion.h2 
              className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-gray-800 dark:text-white flex items-center gap-2`}
              whileHover={{ scale: isMobile ? 1.01 : 1.02 }}
            >
              <FaExchangeAlt className="text-violet-600 dark:text-violet-400" />
              Exchange BIDZ
            </motion.h2>
            
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className={`mt-3 flex items-center gap-2 text-gray-600 dark:text-gray-300 ${isMobile ? 'text-sm' : ''}`}
            >
              <FaWallet className="text-violet-500" />
              <span className="font-mono">{shortenAddress(address)}</span>
            </motion.div>

            <motion.div 
              className={`mt-4 p-3 bg-gradient-to-r from-violet-500/5 to-indigo-500/5 rounded-lg border border-violet-100 dark:border-violet-900 ${isMobile ? 'text-sm' : ''}`}
              whileHover={{ scale: isMobile ? 1.01 : 1.02 }}
            >
              <div className="flex items-center justify-between">
                <span className="text-gray-600 dark:text-gray-400">Current Rate:</span>
                <span className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold text-violet-600 dark:text-violet-400`}>
                  ${currentRate?.toFixed(6) || '-.--'}
                </span>
              </div>
              <div className={`${isMobile ? 'text-xs' : 'text-sm'} text-gray-500 dark:text-gray-400 mt-2`}>
                <FaInfoCircle className="inline mr-1" />
                per BIDZ token
              </div>
            </motion.div>
          </div>

          <div className={`${isMobile ? 'p-4' : 'p-8'}`}>
            {dailyLimitReached ? (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={`text-red-500 text-center ${isMobile ? 'p-4' : 'p-6'} bg-red-50 dark:bg-red-900/20 rounded-xl border border-red-200 dark:border-red-800`}
              >
                <FaInfoCircle className={`${isMobile ? 'text-2xl' : 'text-3xl'} mb-2 mx-auto`} />
                <h3 className={`${isMobile ? 'text-lg' : 'text-xl'} font-bold`}>Daily Limit Reached</h3>
                <p className="mt-2 text-red-600 dark:text-red-400">
                  Please try again tomorrow
                </p>
              </motion.div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className={`block ${isMobile ? 'text-sm' : ''} font-medium text-gray-700 dark:text-gray-300 mb-2`}>
                    BIDZ Amount (Minimum: 100 BIDZ)
                  </label>
                  <motion.div
                    whileFocus={{ scale: isMobile ? 1.01 : 1.02 }}
                    className="relative"
                  >
                    <input
                      type="text"
                      value={bidzAmount}
                      onChange={handleAmountChange}
                      required
                      className={`w-full ${isMobile ? 'px-3 py-2 text-base' : 'px-4 py-3'} 
                        bg-gray-50 dark:bg-slate-900 rounded-xl 
                        border-2 
                        ${parseFloat(bidzAmount) < 100 && bidzAmount !== '' ? 'border-red-500 text-red-500' : 
                          parseFloat(bidzAmount) > remainingLimit ? 'border-red-500 text-red-500' : 
                          'border-violet-100 dark:border-violet-900'} 
                        focus:border-violet-500 focus:ring-violet-500 
                        transition-all duration-200
                      `}
                      placeholder="Enter BIDZ amount (min: 100)"
                    />
                    <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400">
                      BIDZ
                    </div>
                  </motion.div>
                  <AnimatePresence>
                    <WarningMessage amount={bidzAmount} limit={remainingLimit} />
                  </AnimatePresence>
                  <LimitInfo />
                </div>

                <AnimatePresence>
                  {estimatedUsd > 0 && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className={`p-3 bg-gradient-to-r from-green-500/5 to-emerald-500/5 rounded-xl border border-green-100 dark:border-green-900 ${isMobile ? 'text-sm' : ''}`}
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-gray-600 dark:text-gray-400">You'll Receive:</span>
                        <div className="flex items-center gap-2">
                          <FaDollarSign className="text-green-500" />
                          <span className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold text-green-600 dark:text-green-400`}>
                            {estimatedUsd.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>

                <motion.button
                  type="submit"
                  disabled={loading || !bidzAmount || parseFloat(bidzAmount) < 100 || parseFloat(bidzAmount) > remainingLimit || parseFloat(bidzAmount) > monthlyRemaining}
                  whileHover={{ scale: isMobile ? 1.01 : 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`
                    w-full ${isMobile ? 'py-3 text-base' : 'py-4'} px-6 rounded-xl font-medium text-white
                    flex items-center justify-center gap-3
                    ${loading || !bidzAmount || parseFloat(bidzAmount) < 100 || parseFloat(bidzAmount) > remainingLimit || parseFloat(bidzAmount) > monthlyRemaining
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700'}
                    transition-all duration-200 shadow-lg hover:shadow-xl
                  `}
                >
                  {loading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      <span>Processing...</span>
                    </>
                  ) : (
                    <>
                      <span>Swap BIDZ</span>
                      <FaArrowRight />
                    </>
                  )}
                </motion.button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeForm;