import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ExchangeForm from '../components/ExchangeForm';

const Exchange = () => {
  return (
    <div className="pt-20 min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            SWAP BIDZ Tokens
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Swap your tokens instantly with the best rates
          </p>
        </motion.div>

        <div className="max-w-2xl mx-auto">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6"
          >
            <ExchangeForm />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Exchange;