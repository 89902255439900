import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiHomeLine, 
  RiExchangeLine, 
  RiHistoryLine, 
  RiQuestionLine,
  RiMoonLine,
  RiSunLine,
  RiMenuLine,
  RiCloseLine,
  RiWalletLine,
  RiExchangeDollarLine,
  RiDashboardLine,
  RiCoinLine,
  RiLineChartLine
} from 'react-icons/ri';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Web3 from 'web3';
import axios from 'axios';
import { ThemeContext } from '../../context/ThemeContext';

const Navbar = () => {
  const { isDark, setIsDark } = useContext(ThemeContext);
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [balance, setBalance] = useState(null);
  const [bidzCoinBalance, setBidzCoinBalance] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // BSC Chain Configuration
  const bsc = {
    id: 56,
    name: 'Binance Smart Chain',
    network: 'bsc',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: {
      default: 'https://bsc-dataseed.binance.org/',
    },
    blockExplorers: {
      default: { name: 'BscScan', url: 'https://bscscan.com' },
    },
  };

  const bidzTokenAddress = '0x20de6118c3672659e488d1d45279cdf77391fbdc';
  const bidzTokenAbi = useMemo(() => [
    {
      constant: false,
      inputs: [{ name: 'recipient', type: 'address' }, { name: 'amount', type: 'uint256' }],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: '', type: 'uint256' }],
      type: 'function',
    },
  ], []);

  const menuItems = [
    { 
      title: 'Home', 
      path: '/', 
      icon: RiHomeLine,
      description: 'Dashboard overview'
    },
    { 
      title: 'History', 
      path: '/history', 
      icon: RiHistoryLine,
      description: 'Transaction history'
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    }
  }, []);

  useEffect(() => {
    if (isConnected && chain?.id !== bsc.id && switchNetwork) {
      switchNetwork(bsc.id);
    }
  }, [isConnected, chain, switchNetwork, bsc.id]);

  useEffect(() => {
    const fetchBalance = async (walletAddress) => {
      try {
        const response = await axios.get(`https://bidzswap.bidz.store/api/exchange/balance/wallet/${walletAddress}`);
        setBalance(response.data.balance);
      } catch {
        setErrorMessage('Failed to fetch balance.');
      }
    };

    const fetchBidzCoinBalance = async (walletAddress) => {
      try {
        if (web3) {
          const bidzContract = new web3.eth.Contract(bidzTokenAbi, bidzTokenAddress);
          const balance = await bidzContract.methods.balanceOf(walletAddress).call();
          const bidzBalance = web3.utils.fromWei(balance, 'ether');
          setBidzCoinBalance(bidzBalance);
        }
      } catch {
        setErrorMessage('Failed to fetch BIDZ coin balance.');
      }
    };

    if (address && web3 && chain?.id === bsc.id) {
      fetchBalance(address);
      fetchBidzCoinBalance(address);
    }
  }, [address, web3, chain, bidzTokenAbi, bidzTokenAddress]);

  const NavLink = ({ item, mobile = false }) => {
    const Icon = item.icon;
    return (
      <Link
        to={item.path}
        onClick={() => mobile && setIsOpen(false)}
        className={`group flex items-center space-x-3 ${
          mobile
            ? 'px-4 py-3 hover:bg-gray-100 dark:hover:bg-slate-800 rounded-xl transition-colors'
            : 'px-3 py-2 hover:bg-gray-100 dark:hover:bg-slate-800 rounded-lg transition-all duration-200 transform hover:scale-105'
        }`}
      >
        <div className={`${
          mobile ? 'text-2xl' : 'text-xl'
        } text-gray-600 dark:text-gray-400 group-hover:text-violet-600 dark:group-hover:text-violet-400 transition-colors`}>
          <Icon />
        </div>
        <div className="flex flex-col">
          <span className="text-gray-800 dark:text-gray-200 group-hover:text-violet-600 dark:group-hover:text-violet-400 font-medium">
            {item.title}
          </span>
          {mobile && (
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {item.description}
            </span>
          )}
        </div>
      </Link>
    );
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled 
        ? 'bg-white/90 dark:bg-slate-900/90 backdrop-blur-lg shadow-lg' 
        : 'bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-3">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="flex items-center space-x-2"
            >
              <RiDashboardLine className="w-8 h-8 text-violet-600" />
              <div>
                <span className="text-2xl font-bold bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-transparent">
                  BIDZ
                </span>
                <span className="hidden sm:inline-block ml-1 text-sm font-medium text-gray-600 dark:text-gray-400">
                  SWAP
                </span>
              </div>
            </motion.div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-2">
            {menuItems.map((item) => (
              <NavLink key={item.path} item={item} />
            ))}
          </div>

          {/* Right Side Items */}
          <div className="hidden md:flex items-center space-x-4">
            {/* Dark Mode Toggle */}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsDark(!isDark)}
              className="p-2 rounded-full bg-gray-100 dark:bg-slate-800 hover:bg-gray-200 dark:hover:bg-slate-700 transition-all duration-200"
              aria-label="Toggle Dark Mode"
            >
              {isDark ? (
                <RiSunLine className="w-5 h-5 text-yellow-500" />
              ) : (
                <RiMoonLine className="w-5 h-5 text-slate-700" />
              )}
            </motion.button>

            {/* Wallet Information */}
            {isConnected && (
              <div className="flex items-center space-x-4 bg-gray-100 dark:bg-slate-800 rounded-xl p-3">
                <RiWalletLine className="w-5 h-5 text-violet-600 dark:text-violet-400" />
                <div className="text-sm">
                  {balance && (
                    <div className="flex items-center space-x-2 text-gray-700 dark:text-gray-300 font-medium">
                      <RiCoinLine className="w-4 h-4" />
                      <span>{parseFloat(balance).toFixed(2)} BIDZ</span>
                    </div>
                  )}
                  {bidzCoinBalance && (
                    <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-400 text-xs">
                      <RiLineChartLine className="w-4 h-4" />
                      <span>{parseFloat(bidzCoinBalance).toFixed(2)} On-chain</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            
            <ConnectButton />
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-3">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsDark(!isDark)}
              className="p-2 rounded-full bg-gray-100 dark:bg-slate-800 hover:bg-gray-200 dark:hover:bg-slate-700 transition-colors"
            >
              {isDark ? (
                <RiSunLine className="w-5 h-5 text-yellow-500" />
              ) : (
                <RiMoonLine className="w-5 h-5 text-slate-700" />
              )}
            </motion.button>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg bg-gray-100 dark:bg-slate-800 hover:bg-gray-200 dark:hover:bg-slate-700 transition-colors"
            >
              {isOpen ? (
                <RiCloseLine className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              ) : (
                <RiMenuLine className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              )}
            </button>
          </div>
        </div>
      </div>

   {/* Mobile Menu */}
< AnimatePresence>
  {isOpen && (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-x-0 top-16 z-50 md:hidden"
    >
      <div className="relative">
        <div className="absolute inset-0 bg-white/80 dark:bg-slate-900/80 backdrop-blur-lg shadow-lg">
          <div className="px-4 py-4 space-y-4 max-h-[calc(100vh-4rem)] overflow-y-auto">
            {/* Navigation Links */}
            <div className="space-y-1">
              {menuItems.map((item) => (
                <NavLink key={item.path} item={item} mobile />
              ))}
            </div>

            <div className="w-full h-px bg-gray-200 dark:bg-gray-700" />

            {/* Wallet Section */}
            {isConnected ? (
              <div className="space-y-3">
                <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-xl">
                  <div className="flex items-center space-x-3 mb-3">
                    <RiWalletLine className="w-5 h-5 text-violet-600 dark:text-violet-400" />
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      Wallet Balance
                    </span>
                  </div>
                  {balance && (
                    <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-400 mb-2">
                      <RiCoinLine className="w-4 h-4" />
                      <span>{parseFloat(balance).toFixed(2)} BIDZ</span>
                    </div>
                  )}
                  {bidzCoinBalance && (
                    <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-500">
                      <RiLineChartLine className="w-4 h-4" />
                      <span className="text-sm">
                        {parseFloat(bidzCoinBalance).toFixed(2)} On-chain
                      </span>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    disconnect();
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/20 rounded-lg hover:bg-red-100 dark:hover:bg-red-900/40 transition-colors"
                >
                  Disconnect Wallet
                </button>
              </div>
            ) : (
              <div className="px-2">
                <ConnectButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )}
</AnimatePresence>
    </nav>
  );
};

export default Navbar;