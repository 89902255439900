import React from 'react';
import { motion } from 'framer-motion';
import { FiTrendingUp, FiUsers, FiClock, FiAward } from 'react-icons/fi';
import WalletConnect from '../components/WalletConnect';

const Home = () => {
  const [stats, setStats] = React.useState({
    dailyStats: {
      usdVolume: 0,
      bidzVolume: 0,
      transactions: 0
    },
    totalStats: {
      usdVolume: 0,
      bidzVolume: 0,
      transactions: 0,
      uniqueUsers: 0
    },
    recentTransactions: []
  });

  React.useEffect(() => {
    fetchAllStats();
  }, []);

  const fetchAllStats = async () => {
    try {
      const response = await fetch('https://bidzswap.bidz.store/api/exchange/dashboard-stats');
      const { data } = await response.json();

      if (data) {
        setStats({
          dailyStats: {
            usdVolume: data.dailyStats.todayUsdVolume || 0,
            bidzVolume: data.dailyStats.todayBidzVolume || 0,
            transactions: data.dailyStats.todayTransactions || 0
          },
          totalStats: {
            usdVolume: data.tradingStats.totalUsdVolume || 0,
            bidzVolume: data.tradingStats.totalBidzVolume || 0,
            transactions: data.tradingStats.totalTransactions || 0,
            uniqueUsers: data.tradingStats.uniqueUsers || 0
          },
          recentTransactions: data.recentTransactions || []
        });
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  return (
    <main className="container mx-auto px-4 py-12">
      <section className="text-center mb-20">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl md:text-6xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-indigo-600 dark:from-violet-400 dark:to-indigo-400"
        >
          BIDZ Swap
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-lg md:text-xl text-gray-700 dark:text-gray-300 max-w-2xl mx-auto"
        >
          The most secure and efficient way to swap your BIDZ tokens
        </motion.p>
      </section>

      <section className="mb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            { 
              icon: <FiTrendingUp />, 
              label: "24h Volume", 
              value: `$${(stats.dailyStats.usdVolume || 0).toLocaleString()}`,
              subtitle: `${(stats.dailyStats.bidzVolume || 0).toLocaleString()} BIDZ`
            },
            { 
              icon: <FiUsers />, 
              label: "Total Volume", 
              value: `$${(stats.totalStats.usdVolume || 0).toLocaleString()}`,
              subtitle: `${(stats.totalStats.bidzVolume || 0).toLocaleString()} BIDZ`
            },
            { 
              icon: <FiClock />, 
              label: "Transactions", 
              value: (stats.totalStats.transactions || 0).toLocaleString(),
              subtitle: `${(stats.dailyStats.transactions || 0).toLocaleString()} Today`
            },
            { 
              icon: <FiAward />, 
              label: "Active Users", 
              value: (stats.totalStats.uniqueUsers || 0).toLocaleString(),
              subtitle: "Unique Users"
            }
          ].map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-xl dark:shadow-gray-900/30 hover:shadow-2xl transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-violet-100 dark:bg-violet-900/30 rounded-xl text-violet-600 dark:text-violet-400">
                  {stat.icon}
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                    {stat.label}
                  </p>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white">
                    {stat.value}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-500">
                    {stat.subtitle}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="mb-20">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-2xl p-8 transition-all duration-300">
            <WalletConnect />
          </div>
        </div>
      </section>

      <section className="mb-20">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-2xl p-8 transition-all duration-300">
            <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
              Recent Transactions
            </h2>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b dark:border-gray-700">
                    <th className="p-4 text-gray-600 dark:text-gray-400">Hash</th>
                    <th className="p-4 text-gray-600 dark:text-gray-400">From</th>
                    <th className="p-4 text-gray-600 dark:text-gray-400">To</th>
                    <th className="p-4 text-gray-600 dark:text-gray-400">Amount</th>
                    <th className="p-4 text-gray-600 dark:text-gray-400">USD Value</th>
                    <th className="p-4 text-gray-600 dark:text-gray-400">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {stats.recentTransactions.map((tx, index) => (
                    <tr key={index} className="border-b dark:border-gray-700">
                      <td className="p-4 font-mono text-sm text-gray-900 dark:text-gray-300">
                        {tx.transaction_hash?.substring(0, 16)}...
                      </td>
                      <td className="p-4 text-gray-600 dark:text-gray-400">
                        {tx.transaction_from?.substring(0, 8)}...
                      </td>
                      <td className="p-4 text-gray-600 dark:text-gray-400">
                        {tx.transaction_to?.substring(0, 8)}...
                      </td>
                      <td className="p-4 text-gray-900 dark:text-gray-300">
                        {parseFloat(tx.bidz_coin).toFixed(2)} BIDZ
                      </td>
                      <td className="p-4 text-gray-900 dark:text-gray-300">
                        ${parseFloat(tx.usd_amount).toFixed(2)}
                      </td>
                      <td className="p-4">
                        <span className={`px-3 py-1 rounded-full text-xs ${
                          tx.status === 'completed'
                            ? 'bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-400'
                            : 'bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-400'
                        }`}>
                          {tx.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;