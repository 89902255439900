import React from 'react';
import { motion } from 'framer-motion';

const Terms = () => {
  const terms = [
    {
      title: "1. Introduction",
      content: "Welcome to BIDZ Exchange. By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions."
    },
    {
      title: "2. Definitions",
      content: `"Service" refers to the BIDZ Exchange platform
                "User" refers to anyone accessing or using the Service
                "Terms" refers to these Terms of Service`
    },
    {
      title: "3. Service Usage",
      content: "Users must be at least 18 years old to use our services. Users are responsible for maintaining the security of their account and wallet information."
    },
    {
      title: "4. User Obligations",
      content: "Users agree to provide accurate information and maintain the security of their accounts. Users are responsible for all activities that occur under their account."
    },
    // Add more sections as needed
  ];

  return (
    <div className="pt-20 min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Terms of Service
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Please read these terms carefully before using our platform
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto">
          {terms.map((term, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="mb-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6"
            >
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {term.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-400 whitespace-pre-line">
                {term.content}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terms;