import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { FaWallet, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import axios from 'axios';

const API_BASE_URL = 'https://bidzswap.bidz.store/api/exchange';
const ITEMS_PER_PAGE = 10;

const History = () => {
  const { address, isConnected } = useAccount();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState('created_date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [view, setView] = useState('all');

  useEffect(() => {
    fetchTransactions();
  }, [view, address, isConnected, sortField, sortDirection, currentPage]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const endpoint = view === 'personal' && isConnected
        ? `/transaction-history?userId=${address}`
        : '/all-transactions';

      const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
        params: {
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          sortField,
          sortDirection
        }
      });

      setTransactions(response.data.transactions);
      setTotalPages(response.data.totalPages);
      setError(null);
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError('Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field) => {
    setSortDirection(sortField === field && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortField(field);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const shortenAddress = (addr) => {
    if (!addr) return '';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  const getTransactionStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
    }
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort className="ml-1 inline" />;
    return sortDirection === 'asc' ? <FaSortUp className="ml-1 inline" /> : <FaSortDown className="ml-1 inline" />;
  };

  const renderPagination = () => {
    return (
      <div className="flex justify-center items-center space-x-4 mt-6">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1 || loading}
          className={`px-4 py-2 rounded-lg transition-colors ${
            currentPage === 1 || loading
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-violet-600 text-white hover:bg-violet-700'
          }`}
        >
          Previous
        </button>

        <div className="flex items-center space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter(page => {
              if (totalPages <= 7) return true;
              if (page === 1 || page === totalPages) return true;
              if (page >= currentPage - 2 && page <= currentPage + 2) return true;
              return false;
            })
            .map((page, index, array) => {
              if (index > 0 && array[index - 1] !== page - 1) {
                return (
                  <React.Fragment key={`ellipsis-${page}`}>
                    <span className="px-2">...</span>
                    <button
                      onClick={() => setCurrentPage(page)}
                      className={`w-8 h-8 rounded-full ${
                        currentPage === page
                          ? 'bg-violet-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-violet-100'
                      }`}
                    >
                      {page}
                    </button>
                  </React.Fragment>
                );
              }
              return (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`w-8 h-8 rounded-full ${
                    currentPage === page
                      ? 'bg-violet-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-violet-100'
                  }`}
                >
                  {page}
                </button>
              );
            })}
        </div>

        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages || loading}
          className={`px-4 py-2 rounded-lg transition-colors ${
            currentPage === totalPages || loading
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-violet-600 text-white hover:bg-violet-700'
          }`}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-6xl mx-auto"
      >
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Transaction History
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            View all BIDZ token transactions
          </p>
        </div>

        {/* Wallet Connection */}
        <div className="mb-8 text-center">
          {!isConnected && (
            <div className="p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg">
              <FaWallet className="w-12 h-12 mx-auto text-violet-600 mb-4" />
              <p className="mb-4 text-gray-600 dark:text-gray-400">
                Connect your wallet to view your personal transactions
              </p>
              <ConnectButton />
            </div>
          )}
        </div>

        {/* View Toggle */}
        {isConnected && (
          <div className="flex justify-center space-x-4 mb-6">
            <button
              onClick={() => {
                setView('all');
                setCurrentPage(1);
              }}
              className={`px-6 py-2 rounded-lg transition-colors ${
                view === 'all'
                  ? 'bg-violet-600 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
              }`}
            >
              All Transactions
            </button>
            <button
              onClick={() => {
                setView('personal');
                setCurrentPage(1);
              }}
              className={`px-6 py-2 rounded-lg transition-colors ${
                view === 'personal'
                  ? 'bg-violet-600 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
              }`}
            >
              My Transactions
            </button>
          </div>
        )}

        {/* Transactions Table */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-900">
                <tr>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('transaction_hash')}
                  >
                    Transaction Hash
                    <SortIcon field="transaction_hash" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    From/To
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('bidz_coin')}
                  >
                    Amount
                    <SortIcon field="bidz_coin" />
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('method')}
                  >
                    Method
                    <SortIcon field="method" />
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('status')}
                  >
                    Status
                    <SortIcon field="status" />
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('created_date')}
                  >
                    Date
                    <SortIcon field="created_date" />
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {loading ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center">
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-violet-600"></div>
                      </div>
                    </td>
                  </tr>
                ) : transactions.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                      No transactions found
                    </td>
                  </tr>
                ) : (
                  transactions.map((tx, index) => (
                    <motion.tr
                      key={tx.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <a
                          href={`https://bscscan.com/tx/${tx.transaction_hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-violet-600 hover:text-violet-700"
                        >
                          {shortenAddress(tx.transaction_hash)}
                        </a>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm">
                          <p>From: {shortenAddress(tx.transaction_from)}</p>
                          <p>To: {shortenAddress(tx.transaction_to)}</p>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm">
                          <p>{tx.bidz_coin} BIDZ</p>
                          {tx.usd_amount && (
                            <p className="text-gray-500 text-xs">
                              ${Number(tx.usd_amount).toFixed(2)}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900 dark:text-gray-100">
                          {tx.method}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getTransactionStatusClass(tx.status)}`}>
                          {tx.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {formatDate(tx.created_date)}
                      </td>
                    </motion.tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        {!loading && transactions.length > 0 && renderPagination()}

        {/* Error message */}
        {error && (
          <div className="mt-4 p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg">
            {error}
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default History;