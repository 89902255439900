import React from 'react';
import { motion } from 'framer-motion';

const Privacy = () => {
  const policies = [
    {
      title: "1. Information We Collect",
      content: "We collect information that you provide directly to us when using our services, including but not limited to:\n\n• Wallet addresses\n• Transaction data\n• Usage information"
    },
    {
      title: "2. How We Use Your Information",
      content: "We use the information we collect to provide, maintain, and improve our services, including:\n\n• Processing transactions\n• Preventing fraud\n• Improving user experience"
    },
    {
      title: "3. Information Sharing",
      content: "We do not share your personal information with third parties except as described in this policy or with your explicit consent."
    },
    {
      title: "4. Data Security",
      content: "We implement appropriate security measures to protect your information from unauthorized access, alteration, or destruction."
    },
    // Add more sections as needed
  ];

  return (
    <div className="pt-20 min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-200 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Privacy Policy
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            How we collect, use, and protect your information
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto">
          {policies.map((policy, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="mb-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6"
            >
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {policy.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-400 whitespace-pre-line">
                {policy.content}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Privacy;