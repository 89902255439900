import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WagmiConfig, configureChains, createClient } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Footer from './components/partials/Footer';
import Navbar from './components/partials/Navbar';
import { ThemeContext, ThemeProvider } from './context/ThemeContext';
import useMediaQuery from './hooks/useMediaQuery';
import Exchange from './pages/Exchange';
import FAQ from './pages/FAQ';
import History from './pages/History';
import Home from './pages/Home';
import Terms from './components/Terms.js';
import Privacy from './components/Privacy';
// Wagmi Configuration
const { chains, provider } = configureChains(
  [mainnet],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'BIDZ Token Exchange',
  projectId: 'YOUR_PROJECT_ID', // Replace with your actual WalletConnect Project ID
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

// Responsive Tech Background Component
const TechBackground = ({ isDark, isMobile }) => {
  const particleCount = isMobile ? 15 : 30;
  const lineCount = isMobile ? 4 : 8;

  return (
    <div className="fixed inset-0 -z-10">
      {/* Base gradient */}
      <div className={`absolute inset-0 transition-colors duration-500 
        ${isDark 
          ? 'bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900' 
          : 'bg-gradient-to-br from-gray-100 via-white to-gray-100'
        }`}
      />

      {/* Grid pattern */}
      <div className={`absolute inset-0 transition-opacity duration-500
        ${isDark ? 'opacity-20' : 'opacity-10'}`}
        style={{
          backgroundImage: `linear-gradient(90deg, ${isDark ? '#ffffff' : '#000000'} 1px, transparent 1px),
                           linear-gradient(${isDark ? '#ffffff' : '#000000'} 1px, transparent 1px)`,
          backgroundSize: isMobile ? '20px 20px' : '40px 40px',
        }}
      />

      {/* Floating particles */}
      <div className="absolute inset-0">
        {[...Array(particleCount)].map((_, i) => (
          <motion.div
            key={i}
            className={`absolute rounded-full transition-colors duration-500
              ${isDark ? 'bg-blue-400' : 'bg-violet-400'}`}
            initial={{
              opacity: Math.random() * 0.5 + 0.3,
              scale: Math.random() * 0.5 + 0.5,
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
            }}
            animate={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              transition: {
                duration: Math.random() * (isMobile ? 15 : 20) + 10,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              },
            }}
            style={{
              width: `${Math.random() * (isMobile ? 3 : 4) + 2}px`,
              height: `${Math.random() * (isMobile ? 3 : 4) + 2}px`,
              filter: `blur(${Math.random() * (isMobile ? 1 : 2)}px)`,
            }}
          />
        ))}
      </div>

      {/* Animated lines */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(lineCount)].map((_, i) => (
          <motion.div
            key={`line-${i}`}
            className={`absolute h-[1px] transition-colors duration-500
              ${isDark 
                ? 'bg-gradient-to-r from-blue-500/40 via-blue-300/20 to-transparent' 
                : 'bg-gradient-to-r from-violet-500/40 via-violet-300/20 to-transparent'
              }`}
            initial={{ x: "-100%" }}
            animate={{ x: "200%" }}
            transition={{
              duration: isMobile ? 10 : 15,
              repeat: Infinity,
              ease: "linear",
              delay: i * (isMobile ? 1 : 2),
            }}
            style={{
              top: `${(i + 1) * (isMobile ? 15 : 12)}%`,
              width: `${Math.random() * (isMobile ? 20 : 30) + 20}%`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

// Loading Screen Component
const LoadingScreen = ({ isDark, isMobile }) => {
  return (
    <div className={`fixed inset-0 flex items-center justify-center transition-colors duration-500
      ${isDark ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <motion.div
        className={`${isMobile ? 'w-12 h-12' : 'w-16 h-16'} relative`}
        animate={{ rotate: 360 }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        <div className={`absolute inset-0 rounded-full border-4 border-t-transparent
          ${isDark ? 'border-blue-500' : 'border-violet-500'}`} />
        <motion.div
          className={`absolute inset-0 rounded-full border-4 border-t-transparent
            ${isDark ? 'border-blue-300' : 'border-violet-300'}`}
          animate={{ rotate: -360 }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      </motion.div>
    </div>
  );
};

// Theme Toggle Component
const ThemeToggle = ({ isMobile }) => {
  const { isDark, setIsDark } = React.useContext(ThemeContext);
  
  return (
    <motion.button
      whileHover={{ scale: isMobile ? 1.05 : 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsDark(!isDark)}
      className={`fixed ${isMobile ? 'top-3 right-3' : 'top-4 right-16'} z-50 
        p-2 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300
        ${isDark 
          ? 'bg-gray-800/80 text-white hover:bg-gray-700/80' 
          : 'bg-white/80 text-gray-800 hover:bg-gray-100/80'
        }`}
      aria-label="Toggle Theme"
    >
      {isDark ? <FiSun size={isMobile ? 18 : 20} /> : <FiMoon size={isMobile ? 18 : 20} />}
    </motion.button>
  );
};

// Page Transition Component
const PageTransition = ({ children, isMobile }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: isMobile ? 10 : 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: isMobile ? -10 : -20 }}
      transition={{ duration: isMobile ? 0.2 : 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

// Main Content Component
function AppContent() {
  const { isDark } = React.useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    AOS.init({
      duration: isMobile ? 800 : 1000,
      once: true,
      disable: isMobile,
    });
    
    const timer = setTimeout(() => setIsLoading(false), isMobile ? 1000 : 1500);
    return () => clearTimeout(timer);
  }, [isMobile]);

  if (isLoading) return <LoadingScreen isDark={isDark} isMobile={isMobile} />;

  return (
    <div className="relative min-h-screen">
      <TechBackground isDark={isDark} isMobile={isMobile} />
      
      <div className={`relative z-10 min-h-screen transition-colors duration-500
        ${isDark 
          ? 'bg-gray-900/50' 
          : 'bg-white/50'
        } backdrop-blur-sm`}>
        <ThemeToggle isMobile={isMobile} />
        
        <ToastContainer 
          position={isMobile ? "bottom-center" : "top-right"}
          theme={isDark ? "dark" : "light"}
          autoClose={5000}
          hideProgressBar={isMobile}
          closeButton={!isMobile}
          draggable={!isMobile}
          className={isMobile ? "!mt-16" : ""}
        />

        <Navbar isMobile={isMobile} />

        <main className={`container mx-auto ${isMobile ? 'px-3 py-6' : 'px-4 py-8'}`}>
          <AnimatePresence mode="wait">
          <Routes>
  <Route path="/" element={
    <PageTransition isMobile={isMobile}>
      <Home />
    </PageTransition>
  } />
  <Route path="/exchange" element={
    <PageTransition isMobile={isMobile}>
      <Exchange />
    </PageTransition>
  } />
  <Route path="/history" element={
    <PageTransition isMobile={isMobile}>
      <History />
    </PageTransition>
  } />

  <Route path="/terms" element={
    <PageTransition isMobile={isMobile}>
      <Terms />
    </PageTransition>
  } />
  <Route path="/privacy" element={
    <PageTransition isMobile={isMobile}>
      <Privacy />
    </PageTransition>
  } />
  {/* Catch all other routes and redirect to home */}
  <Route path="*" element={
    <PageTransition isMobile={isMobile}>
      <Home />
    </PageTransition>
  } />
</Routes>
          </AnimatePresence>
        </main>

        <Footer isMobile={isMobile} />
      </div>
    </div>
  );
}

// Root Component
function App() {
  return (
    <ThemeProvider>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <AppContent />
        </RainbowKitProvider>
      </WagmiConfig>
    </ThemeProvider>
  );
}

export default App;