import React, { useEffect, useState, useMemo } from 'react';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { configureChains, WagmiConfig, createClient } from 'wagmi';
import { RainbowKitProvider, ConnectButton, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { motion, AnimatePresence } from 'framer-motion';
import { FaWallet, FaExchangeAlt, FaSignOutAlt, FaChartLine, FaExclamationCircle } from 'react-icons/fa';
import Web3 from 'web3';
import axios from 'axios';
import ExchangeForm from './ExchangeForm';
import '@rainbow-me/rainbowkit/styles.css';

// BSC Chain Configuration
const bsc = {
  id: 56,
  name: 'Binance Smart Chain',
  network: 'bsc',
  nativeCurrency: {
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: {
    default: 'https://bsc-dataseed.binance.org/',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://bscscan.com' },
  },
};

const { chains, provider } = configureChains([bsc, mainnet], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: 'BIDZ Token Exchange',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// Animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6 }
  },
  exit: { 
    opacity: 0,
    y: -20,
    transition: { duration: 0.3 }
  }
};

const WalletConnect = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();

  const [web3, setWeb3] = useState(null);
  const [balance, setBalance] = useState(null);
  const [bidzCoinBalance, setBidzCoinBalance] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const bidzTokenAddress = '0x20de6118c3672659e488d1d45279cdf77391fbdc';
  const bidzTokenAbi = useMemo(() => [
    {
      constant: false,
      inputs: [{ name: 'recipient', type: 'address' }, { name: 'amount', type: 'uint256' }],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: '', type: 'uint256' }],
      type: 'function',
    },
  ], []);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else {
      setErrorMessage('Please install a wallet to use this feature.');
    }
  }, []);

  useEffect(() => {
    if (isConnected && chain?.id !== bsc.id && switchNetwork) {
      switchNetwork(bsc.id);
    }
  }, [isConnected, chain, switchNetwork]);

  const fetchBalances = async (walletAddress) => {
    setIsLoading(true);
    try {
      // Fetch backend balance
      const response = await axios.get(`https://bidzswap.bidz.store/api/exchange/balance/wallet/${walletAddress}`);
      setBalance(response.data.balance);

      // Fetch on-chain balance
      if (web3) {
        const bidzContract = new web3.eth.Contract(bidzTokenAbi, bidzTokenAddress);
        const balance = await bidzContract.methods.balanceOf(walletAddress).call();
        const bidzBalance = web3.utils.fromWei(balance, 'ether');
        setBidzCoinBalance(bidzBalance);
      }
    } catch (error) {
      setErrorMessage('Failed to fetch balances.');
      console.error('Error fetching balances:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (address && web3 && chain?.id === bsc.id) {
      fetchBalances(address);
    }
  }, [address, web3, chain]);

  const shortenAddress = (addr) => {
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <AnimatePresence mode='wait'>
          <motion.div
            key={isConnected ? 'connected' : 'disconnected'}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="min-h-screen bg-gradient-to-br from-violet-50 to-indigo-50 dark:from-gray-900 dark:to-slate-900 p-4 sm:p-6 md:p-8"
          >
            <div className="max-w-4xl mx-auto">
              <div className="backdrop-blur-lg bg-white/90 dark:bg-gray-800/90 rounded-2xl shadow-2xl border border-gray-200 dark:border-gray-700 overflow-hidden">
                {!isConnected ? (
                  <div className="p-8 text-center space-y-6">
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ type: "spring", stiffness: 260, damping: 20 }}
                    >
                      <FaWallet className="w-16 h-16 mx-auto text-violet-600 dark:text-violet-400" />
                    </motion.div>
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                      Connect Your Wallet
                    </h2>
                    <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto">
                      Connect your wallet to start exchanging BIDZ tokens securely and efficiently.
                    </p>
                    <div className="mt-8">
                      <ConnectButton />
                    </div>
                  </div>
                ) : (
                  <div className="p-8">
                    <div className="text-center space-y-6">
                      <h2 className="text-3xl font-bold text-gray-900 dark:text-white flex items-center justify-center gap-3">
                        <FaExchangeAlt className="text-violet-600 dark:text-violet-400" />
                        BIDZ Swaping Dashboard
                      </h2>
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          className="p-6 bg-gradient-to-r from-violet-500/5 to-indigo-500/5 rounded-xl border border-violet-100 dark:border-violet-900"
                        >
                          <div className="flex items-center justify-between mb-4">
                            <span className="text-gray-600 dark:text-gray-400">Wallet Address</span>
                            <FaWallet className="text-violet-500" />
                          </div>
                          <p className="font-mono text-lg text-violet-600 dark:text-violet-400">
                            {shortenAddress(address)}
                          </p>
                        </motion.div>

                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          className="p-6 bg-gradient-to-r from-green-500/5 to-emerald-500/5 rounded-xl border border-green-100 dark:border-green-900"
                        >
                          <div className="flex items-center justify-between mb-4">
                            <span className="text-gray-600 dark:text-gray-400">BIDZ Balance</span>
                            <FaChartLine className="text-green-500" />
                          </div>
                          {isLoading ? (
                            <div className="animate-pulse h-6 bg-gray-200 dark:bg-gray-700 rounded w-24" />
                          ) : (
                            <p className="text-lg font-bold text-green-600 dark:text-green-400">
                              {bidzCoinBalance} BIDZ
                            </p>
                          )}
                        </motion.div>
                      </div>

                      {errorMessage && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 rounded-xl border border-red-200 dark:border-red-800 text-red-600 dark:text-red-400 flex items-center gap-2"
                        >
                          <FaExclamationCircle />
                          {errorMessage}
                        </motion.div>
                      )}

                      <div className="mt-8">
                        <ExchangeForm walletAddress={address} />
                      </div>

                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={disconnect}
                        className="mt-8 px-6 py-3 bg-red-600 hover:bg-red-700 text-white rounded-xl transition-all duration-200 flex items-center justify-center gap-2 w-full"
                      >
                        <FaSignOutAlt />
                        Disconnect Wallet
                      </motion.button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default WalletConnect;